import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'

const Footer = () => {

    return(
        <AppBar position='sticky' style={{top: 'auto', bottom: 0,  maxWidth: '80%', margin: 'auto', background: '#19191f66', height: '7vh'}}>
            <Paper style={{background: '#05030c66', height: '100%'}}>
                <Toolbar>
                    <Typography align='center' style={{color: 'white', margin: 'auto', fontFamily: 'Technoma'}}>Xirdie Studios © 2019. All rights reserved</Typography>
                </Toolbar>
            </Paper>
        </AppBar>
    );
}

export default Footer;