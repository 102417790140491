import React, {useState} from 'react';
import Header from '../layouts/header';
import Body from '../layouts/body';
import Footer from '../layouts/footer';
import Home from '../pages/home';
import About from '../pages/about';
import Contact from '../pages/contact';
import Lunaris from '../pages/lunaris';

export const AppState = {Unkown:'unkown', Home:'home', About:'about', Contact: 'contact', Lunaris: 'lunaris'}

const Main = (props) => {

    const [appState, setAppState] = useState(AppState.Unkown);
    const [body, setBody] = useState(<Home onButtonPress={(state) => setAppState(state)}/>);

    if(props.state !== appState)
    {
        setAppState(props.state)
        switch(appState)
        {
            case AppState.Lunaris:
                setBody(<Lunaris />);
            break;
            case AppState.Contact:
                setBody(<Contact />);
            break;
            case AppState.About:
                setBody(<About />);
            break;
            default:
            case AppState.Home:
                setBody(<Home onButtonPress={(state) => setAppState(state)}/>);
            break;
        }
    }

    return (
        <div>
            <Header onButtonPress={(state) => setAppState(state)} />
            <Body body={body}/> 
            <Footer />
        </div>
    );
}

export default Main;