import React from 'react';
import Typography from '@material-ui/core/Typography'

const Contact = () => {
    return(
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography align='center' style={{color: 'white', margin: 'auto'}}>Contact</Typography>
        </div>
    );
}

export default Contact;