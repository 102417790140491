import React from 'react';
import Typography from '@material-ui/core/Typography'

const About = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40vh',
            
        }}>
            <div>
                <Typography variant='h5' style={{color: 'white', fontFamily: 'Technoma' }}>
                    Xirdie Studios was founded in 2019, somewhere in the rolling hills of the Palouse.
                </Typography>
                <Typography variant='h6' style={{color: 'white', fontFamily: 'Technoma' }}>
                    Some say if you listen closely to the wheat fields at night you can hear us stumbling around trying to find our way out.
                </Typography>
                <Typography variant='h5' style={{color: 'white', marginTop: 15,  fontFamily: 'Technoma'}}>
                    We are focused on developing story driven action packed games.
                </Typography>
            </div>
        </div>
    );
}

export default About;