import React from 'react';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItemText'
import {AppState} from '../app/main';
import { Avatar, IconButton} from '@material-ui/core';

const StyledMenu = withStyles({
    paper: {
      background: '#19191f66'
    },
  })((props) => (
    <Menu
      elevation={0}
      background='#19191f66'
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
root: {
    '&:focus': {
    background: '#19191f66',
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white', fontSize: 20, fontFamily: 'DragonSlapper', width: 152, textAlign: 'center'
    },
    },
},
}))(MenuItem);

const Header = (props) => {

    //const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return([
        <AppBar position="sticky" style={{maxWidth: '80%', margin: 'auto', background: '#19191f66', maxHeight: '11vh'}}>
            <Paper style={{background: '#05030c66', maxHeight: '11vh'}}>
                <Toolbar>
                    <IconButton onClick={() => props.onButtonPress(AppState.Home)}>
                        <Avatar variant="img" style={{maxWidth: 250, borderRadius: 0}} alt="Xirdie Studios" src="/images/XS.png"/>
                    </IconButton>
                    <div style={{marginLeft: 'auto'}}>
                        <Grid container alignItems="center">
                            <Button variant='text' onClick={handleClick} >
                                <Typography style={{color: '#FFFFFF', fontFamily: 'VerminVerile', fontSize: 20, margin: 5, }}>Projects</Typography>
                            </Button>
                            <Typography style={{color: '#FFFFFF',
                                        marginLeft: '2em', 
                                        borderLeft: '0.1em solid white',
                                        padding: '1em'}}/>
                            <Button onClick={() => props.onButtonPress(AppState.About)}>
                                <Typography style={{color: '#FFFFFF', fontFamily: 'VerminVerile', 
                                        fontSize: 20}}>About Us</Typography>
                            </Button>
                            {/* <Typography style={{color: '#FFFFFF',
                                        marginLeft: '2em', 
                                        borderLeft: '0.1em solid white',
                                        padding: '1em'}}/>
                            <Button onClick={() => props.onButtonPress(AppState.Contact)}>
                                <Typography style={{color: '#FFFFFF', fontFamily: 'VerminVerile', fontSize: 20, margin: 5}}>Contact Us</Typography>
                            </Button> */}
                        </Grid>
                    </div>
                </Toolbar>
            </Paper>
        </AppBar>,
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
        <StyledMenuItem>
            <ListItem button onClick={() => {handleClose();props.onButtonPress(AppState.Lunaris);}} primary="Lunaris" background='#05030c66' fontFamily='Dragonslapper' ></ListItem>
        </StyledMenuItem>
      </StyledMenu>
    ]);
}

export default Header;