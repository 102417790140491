import React from 'react';
import Typography from '@material-ui/core/Typography'
import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import {AppState} from '../app/main'

const Home = (props) =>{
    return(
        <div style={{
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh',
            
        }}>
                <Card style={{width: 500, minHeight: 500, background: '#19191f66'}}>
                    <CardContent>
                    <Typography variant='h3' style={{color: 'white', fontFamily: 'VerminVerile', textAlign:'center'}}>
                            News
                        </Typography>
                        <Typography variant='h6' style={{color: 'white', textAlign: 'center', fontFamily: 'Technoma'}}>
                            We are currently busy working on our first project
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button style={{margin: 'auto'}} onClick={() => props.onButtonPress(AppState.Lunaris)}>
                            <Typography variant='h4' style={{color: 'white', fontFamily: 'DragonSlapper'}}>
                                Lunaris
                            </Typography>
                        </Button>
                    </CardActions>
                </Card>
                
                {/* <Typography variant='h3' style={{color: '#1a1140', fontFamily: 'VerminVerile', marginLeft: 20, marginRight: 20}}>
                    Xirdie
                </Typography>
                <Typography variant='h3' style={{color: '#7ce124', fontFamily: 'VerminVerile'}}>
                    {' Studios'}
                </Typography> */}
        </div>
    );
}

export default Home;