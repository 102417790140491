import React from 'react';
import Typography from '@material-ui/core/Typography'
import { List, ListItem } from '@material-ui/core';

const Lunaris = () =>{
    return(
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            justifyItems: 'center',
            height: '50vh',
            
        }}>
            <div>
                <Typography variant='h1' style={{color: 'white', fontFamily: 'Dragonslapper', textAlign:'center'}}>
                    Lunaris
                </Typography>
                <Typography variant='h5' style={{color: 'white', marginTop: 15, fontFamily: 'Technoma'}}>
                    An action packed adventure game that follows Lunaris as she's the first to confront the dark forces that manipulate her world 
                </Typography>
                <Typography variant='h3' style={{color: 'white', fontFamily: 'Dragonslapper', marginTop: 40}}>
                    Development State:
                </Typography>
                <List>
                    <ListItem>
                        <Typography variant='h4' style={{color: 'white',  marginTop: 5, fontFamily: 'Technoma'}}>
                            Story: Writing First Draft
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='h4' style={{color: 'white',  marginTop: 5, fontFamily: 'Technoma'}}>
                            Game: Asset Acquisition  / Creation & Systems Development
                        </Typography>
                    </ListItem>
                </List>
            </div>
        </div>
    );
}

export default Lunaris;